import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import Header from '../components/Header';
const { Content, Footer } = Layout;
import BandeauDuBas from '../public/bandeau_du_bas.png';

function index() {
  const screens = useBreakpoint();
  const [login, setLogin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [emailNotValid, setEmailNotValid] = React.useState('');
  const [emailExist, setEmailExist] = useState('');
  const [email, setEmail] = useState();
  let router = useRouter();
  console.log('email', email)
  const connectUser = async (email) => {
    console.log(email);

    return new Promise((resolve, reject) => {
      fetch('/api/login', {
        method: 'post',
        body: JSON.stringify({
          email: email.email.toLowerCase()
        }),
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const onFinish = (email) => {
    console.log('Success:', email);
    if (email) {
      setLoading(true);
      connectUser(email)
        .then((res) => {
          Cookies.set('conected', email.email);
          setLogin(true);
          router.push('/live/fr');
        })
        .catch((err) => {
          setModalOpen(true);
          setLoading(false);
          setEmailExist(
            'Votre e-mail n’est pas référencé, il n’est pas possible d’entrer dans la webconférence.'
          );
          console.log('erreur');
        });
    }
  };
  useEffect(() => {
    if (Cookies.get('conected')) {
      router.push('/live/fr');
    }
  }, [login]);
  return (
    <>
      <Layout
        style={{
          maxWidth: '1024px',
          margin: '0 auto',
          height: '100vh'
        }}
      >
        <Header />
        <Content
          style={{
            backgroundColor: 'white'
          }}
        >
          <Form
            style={{
              display: 'flex',
              flexDirection: screens.md ? 'row' : 'column',
              justifyContent: 'center',
              margin: '0 auto',
              height:"100%"
            }}
            onFinish={onFinish}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                margin: '0 auto',
                // height: 'calc(60vh - 100px)',
                alignItems: 'center',
                padding: 12
              }}
            >
              <div
                style={{
                  fontSize: screens.xs ? '5vw' : '2vw',
                  fontWeight: 900,
                  textAlign: 'left',
                  padding: '10px 0',
                  color: '#005d83'
                }}
                className="title"
              >
                <h1>Entrez votre adresse email pour accéder au direct</h1>
                <h1>Enter your email address to access the live stream</h1>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  margin: '0 auto',
                  width: '80%',
                  alignItems: 'center'
                }}
              >
                {emailExist && (
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    Votre email n'est pas référencé, il n'est pas possible d'entrer dans la webconférence.
                  </p>
                )}
                <Form.Item
                name="email"
                label="Email"
                onChange={(e) =>
                  e.target.value.length === 0 && setEmailExist('')
                }
                style={{
                  width:"100%"
                }}
                >
                  <Input
                    // addonBefore="E-mail"
                    style={{
                      borderColor: '#005d83',
                      borderWidth: 2,
                      marginRight: 0,
                      width:"100%"
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  style={{
                    display: 'inline',
                    color: 'white',
                    fontSize: 12,
                    // height: 42,
                    line: 1,
                    textTransform: 'uppercase',
                    background: '#005d83',
                    borderColor: '#005d83',
                    padding: '0 20px',
                    // maxWidth: 200,
                    alignSelf:"end",
                    marginTop: screens.md ? 10 : 20,
                    // marginLeft: screens.md ? 50 : 0
                  }}
                >
                  {' '}
                  Se connecter / Connect
                </Button>
              </Form.Item>
              </div>
            </div>
          </Form>
        </Content>
          
        <style jsx>
          {`
            .title h1 {
              color: #005d83;
            }
            .ant-form-item {
              width: 100% !important;
            }
          `}
        </style>
        <Footer style={{ padding: 0 }}>
          <img src={BandeauDuBas.src} style={{ width: '100%' }} />
        </Footer>
        <style jsx>{`
        
          .container {
            max-width: 1024px;
            margin: 0 auto;
            padding: 0;
          }
          .ant-form-item {
            width: 100% !important;
          }
          .content {
            //   width: 50%;
            margin: 0 auto;
            text-align: center;
            margin: 20px 0;
          }
          .login {
            margin: 40px 0;
            height: calc(100vh - 432px);
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .content .form {
            width: ${screens.md ? '80%' : '90%'};
            margin: 0 auto;
          }

          .header img {
            width: 100%;
          }
          .footer {
            width: 100%;
            height: 100px;
          }
          .footer img {
            width: 100%;
            background-size: cover;
          }
          h1 {
            font-size: ${screens.xs ? '1.3rem' : '2rem'};
            margin: 0;
            color: #003c61;
            line-height: 1;
          }
          h2 {
            font-size: ${screens.xs ? '1.3rem' : '1.8rem'};
            margin-bottom: 40px;
            color: #003c61;
          }
          .btn {
            border: 1px solid black !important;
          }
          .error {
            color: black;
          }
          .container p {
            text-align: center;
            margin: 0;
          }
          .container p {
            color: unset;
          }
        `}</style>
      </Layout>
    </>
  );
}

export default index;
